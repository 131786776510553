import React, { useRef, useEffect, useContext, useState } from 'react'
import { Link } from 'gatsby'

import { ThemeContext } from '../ThemeSwitcher'
import styles from './Header.module.css'
import Logo from './img/logo.inline.svg'
import Switch from '../Switch'
import { ShowAll } from '../Button'

function getTimeInNY() {
  return new Date(
    new Date().toLocaleString('en-US', { timeZone: 'America/New_York' })
  )
}

function withLeading0(number) {
  return number < 10 ? `0${number}` : `${number}`
}

const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export default function Header(props) {
  const timer = useRef()
  const [date, setDate] = useState(getTimeInNY())

  const { theme, setTheme } = useContext(ThemeContext)

  useEffect(() => {
    timer.current = setInterval(() => setDate(getTimeInNY()), 1000)

    return () => {
      clearInterval(timer.current)
    }
  }, [])

  return (
    <header className={styles.header} {...props}>
      <ShowAll className={styles.showAll} />
      <Switch
        onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
        isOn={theme === 'dark'}
        className={styles.switch}
      />
      <Link to="/">
        <h1 className={styles.name}>
          <Logo className={styles.logo} title="Porto Rocha" />
        </h1>
      </Link>
      <p className={styles.date}>
        {`${days[date.getDay()]}, ${
          months[date.getMonth()]
        } ${date.getDate()},`}
        <br />
        {`New York, ${date.getHours()}:${withLeading0(
          date.getMinutes()
        )}:${withLeading0(date.getSeconds())}`}
      </p>
    </header>
  )
}
