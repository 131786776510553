import React, { useState, useEffect } from 'react'
import Client from 'shopify-buy'
import Cookies from 'js-cookie'
import styles from './Product.module.css'

import Card from '../Card'
import Carousel from '../Carousel'

const client = Client.buildClient({
  domain: 'portorocha.myshopify.com',
  storefrontAccessToken: '5eb14bb61cbdbd3256da1e838e99174d',
})

const formatCurrency = (amount, currencyCode) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 0,
  }).format(amount)

export default function Product() {
  const [products, setProducts] = useState([])
  const [checkout, setCheckout] = useState()
  const [isCartShown, setIsCartShown] = useState(false)

  const getCartStyles = () => {
    const cartStyles = [styles.cart]

    if (isCartShown) {
      cartStyles.push(styles.cartOpen)
    }

    return cartStyles.join(' ')
  }

  const handleAddToCart = variantId => {
    client.checkout
      .addLineItems(checkout.id, [{ variantId, quantity: 1 }])
      .then(setCheckout)
    setIsCartShown(true)
  }

  const handleQuantityUpdate = (lineItemId, quantity) => {
    client.checkout
      .updateLineItems(checkout.id, { id: lineItemId, quantity })
      .then(setCheckout)
  }

  useEffect(() => {
    client.product.fetchAll().then(setProducts)

    const existingCartId = Cookies.get('cart-id')

    if (existingCartId) {
      client.checkout.fetch(existingCartId).then(setCheckout)
    } else {
      client.checkout.create().then(checkoutResponse => {
        setCheckout(checkoutResponse)
        Cookies.set('cart-id', checkoutResponse.id)
      })
    }
  }, [])

  const Container = products.length > 1 ? Carousel : 'div'
  const containerProps =
    products.length > 1
      ? { buttonHeightOffset: 40, slideIndicatorsStyle: { bottom: 12 } }
      : {}

  return (
    <Card
      style={{
        position: 'relative',
        overflow: 'hidden',
        minHeight: 450,
      }}
    >
      <div style={{ width: 'calc(100% + 24px)', margin: '-13px -12px' }}>
        <Container {...containerProps}>
          {products.map(product => {
            const [image] = product.images
            const [variant] = product.variants

            return (
              <div key={product.id}>
                <div className={styles.product}>
                  <img className={styles.image} src={image.src} alt="" />

                  <div className={styles.details}>
                    <div className={styles.titlePrice}>
                      <h2 className={styles.title}>{product.title}</h2>
                      <p>
                        {formatCurrency(
                          variant.priceV2.amount,
                          variant.priceV2.currencyCode
                        )}
                      </p>
                    </div>

                    <button
                      type="button"
                      className={[styles.button, styles.addToCart].join(' ')}
                      onClick={() => handleAddToCart(variant.id)}
                    >
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            )
          })}
        </Container>
      </div>

      {checkout && (
        <section className={getCartStyles()}>
          <button
            type="button"
            className={[styles.button, styles.goBack].join(' ')}
            onClick={() => setIsCartShown(false)}
          >
            Back
          </button>
          <ul className={styles.lineItems}>
            {checkout.lineItems.map(lineItem => {
              const {
                variant: { image, priceV2: price },
              } = lineItem
              return (
                <li key={lineItem.id} className={styles.lineItem}>
                  <img
                    className={styles.lineItemImage}
                    src={image.src}
                    alt={image.altText}
                  />

                  <div className={styles.lineItemDetailsOuter}>
                    <div className={styles.lineItemDetails}>
                      <p>
                        PORTO ROCHA
                        <br />
                        {lineItem.title}
                      </p>
                      <p className={styles.lineItemPrice}>
                        {formatCurrency(
                          price.amount * lineItem.quantity,
                          price.currencyCode
                        )}
                      </p>
                    </div>

                    <div className={styles.quantity}>
                      <button
                        type="button"
                        onClick={() =>
                          handleQuantityUpdate(
                            lineItem.id,
                            lineItem.quantity - 1
                          )
                        }
                      >
                        -
                      </button>
                      <span>{lineItem.quantity}</span>
                      <button
                        type="button"
                        onClick={() =>
                          handleQuantityUpdate(
                            lineItem.id,
                            lineItem.quantity + 1
                          )
                        }
                      >
                        +
                      </button>
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
          <div className={styles.cartFooter}>
            <div className={styles.subtotal}>
              <span>Subtotal</span>
              <span style={{ marginLeft: 'auto' }}>
                {formatCurrency(
                  checkout.lineItemsSubtotalPrice.amount,
                  checkout.lineItemsSubtotalPrice.currencyCode
                )}
              </span>
            </div>

            <a
              href={checkout.webUrl}
              className={[styles.button, styles.checkoutButton].join(' ')}
              target="_blank"
              rel="noreferrer noopener"
            >
              Checkout
            </a>
          </div>
        </section>
      )}
    </Card>
  )
}
