import React, { Fragment, useRef, useState } from 'react'
// import { useMediaQuery } from 'react-responsive'
import PropTypes from 'prop-types'
import ReactSwipe from 'react-swipe'
import Img from 'gatsby-image'

import Image from '../Image'
import ResponsiveVideo from '../ResponsiveVideo'
import Card from '../Card'
import Product from '../Product'
import StreetView from '../StreetView'

import styles from './About.module.css'

export default function About({ data }) {
  const {
    Clients: clients,
    People: people,
    fields: { aboutHTML, contactHTML, contactRightHTML },
    slides,
    hide_street_view: hideStreetView,
  } = data

  const reactSwipeRef = useRef()
  const [currentSlide, setCurrentSlide] = useState(0)

  const renderStudioCard = () => (
    <Card>
      <h2>Our Studio</h2>
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: aboutHTML }} />
    </Card>
  )

  const getIndicatorClass = index => {
    const classes = [styles.indicator]

    if (currentSlide === index) {
      classes.push(styles.active)
    }

    return classes.join(' ')
  }

  const renderStudioPhotos = () => (
    <section className={styles.swipeContainer}>
      <ReactSwipe
        childCount={slides.length}
        ref={reactSwipeRef}
        swipeOptions={{
          startSlide: currentSlide,
          transitionEnd: index => {
            setCurrentSlide(index)
          },
        }}
      >
        {slides.map(item => {
          if (item.image) {
            return (
              <div key={item.id}>
                <Image
                  image={item.image}
                  url={item.image_url}
                  loading="eager"
                  alt=""
                />
              </div>
            )
          }

          if (item.vimeo_url) {
            return (
              <div key={item.id}>
                <ResponsiveVideo
                  style={{ pointerEvents: 'none' }}
                  key={item.id}
                  vimeoId={item.vimeo_url}
                  width={item.width ? item.width : undefined}
                  height={item.height ? item.height : undefined}
                />
              </div>
            )
          }

          return null
        })}
      </ReactSwipe>

      <button
        aria-label="Previous photo"
        className={styles.previous}
        onClick={() => reactSwipeRef.current.prev()}
        type="button"
      />
      <button
        aria-label="Next photo"
        className={styles.next}
        onClick={() => reactSwipeRef.current.next()}
        type="button"
      />

      <div className={styles.indicators}>
        {slides.map((slide, index) => (
          <button
            aria-label={`Go to slide ${index}`}
            className={getIndicatorClass(index)}
            onClick={() => reactSwipeRef.current.slide(index)}
            key={slide.id}
            type="button"
          />
        ))}
      </div>
    </section>
  )

  const renderClientsCard = () => (
    <Card>
      <h2>Clients</h2>
      <ul className={styles.clientList}>
        {clients.map(client => (
          <li key={client.id}>{client.name}</li>
        ))}
      </ul>
    </Card>
  )

  const renderProduct = () => <Product />

  const renderStreetView = () => {
    if (hideStreetView) {
      return null
    }

    return (
      <div className={styles.street}>
        <StreetView
          location={{ lat: 40.7111797, lng: -73.9524845 }}
          heading={-4}
          pitch={-5}
          address1="457 Grand St"
          address2="Brooklyn, NY"
        />
      </div>
    )
  }

  const renderPeople = () =>
    people.map(person => (
      <Fragment key={person.id}>
        <Img fluid={person.photo.childImageSharp.fluid} />
        <Card>
          <h2>
            {person.name}
            <br />
            <span className={styles.title}>{person.title}</span>
          </h2>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: person.bio }} />
        </Card>
      </Fragment>
    ))

  const renderContactCard = () => (
    <Card style={{ marginBottom: 0 }}>
      {/* eslint-disable react/no-danger */}
      <div style={{ display: 'flex' }}>
        <div
          className={styles.contact}
          dangerouslySetInnerHTML={{ __html: contactHTML }}
        />
        <div
          className={styles.contact}
          dangerouslySetInnerHTML={{ __html: contactRightHTML }}
        />
      </div>
      {/* eslint-enable */}
    </Card>
  )

  return (
    <>
      <main className={[styles.about, 'mobile-only'].join(' ')}>
        {renderStudioPhotos()}
        {renderStudioCard()}
        {renderClientsCard()}
        {renderProduct()}
        {renderStreetView()}
        {renderPeople()}
        {renderContactCard()}
      </main>
      <main className={[styles.about, 'desktop-only'].join(' ')}>
        <div className={styles.left}>
          {renderStudioPhotos()}
          <div className={styles.bottom}>
            <div className={styles.bottomCol}>
              {renderStudioCard()}
              {renderClientsCard()}
              {renderContactCard()}
            </div>
            <div className={styles.bottomCol}>
              {renderProduct()}
              {renderStreetView()}
            </div>
          </div>
        </div>
        <div className={styles.right}>{renderPeople()}</div>
      </main>
    </>
  )
}

About.propTypes = {
  data: PropTypes.shape({
    slides: PropTypes.arrayOf(
      PropTypes.shape({
        vimeo_url: PropTypes.string,
      })
    ),
    hide_street_view: PropTypes.bool,
    slug: PropTypes.string,
    fields: PropTypes.shape({
      aboutHTML: PropTypes.string,
      contactHTML: PropTypes.string,
      contactRightHTML: PropTypes.string,
    }),
    Clients: PropTypes.arrayOf({
      name: PropTypes.string,
    }),
    People: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        bio: PropTypes.string,
      })
    ),
  }).isRequired,
}
