import React, { useEffect, useState, useRef } from 'react'

import styles from './About/About.module.css'

const TIMEOUT_LIMIT = 10
// const office = { lat: 40.7112164, lng: -73.9526003 }

export default function StreetView({
  location,
  heading = 45,
  pitch = 5,
  address1,
  address2,
  responsiveHeight = false,
  ...props
}) {
  const panorama = useRef()
  const mapContainer = useRef()
  const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = useState(false)
  const [timeoutCount, setTimeoutCount] = useState(0)

  useEffect(() => {
    if (window.google) {
      setIsGoogleMapsLoaded(true)
    } else if (timeoutCount < TIMEOUT_LIMIT) {
      setTimeout(() => {
        setTimeoutCount(timeoutCount + 1)
      }, 500)
    }
  }, [timeoutCount])

  useEffect(() => {
    if (isGoogleMapsLoaded) {
      panorama.current = new window.google.maps.StreetViewPanorama(
        mapContainer.current,
        {
          position: location,
          disableDefaultUI: true,
          pov: {
            heading,
            pitch,
          },
        }
      )
    }
  }, [isGoogleMapsLoaded, location])

  return (
    <div
      className={[
        styles.streetViewCrop,
        ...(responsiveHeight ? [styles.streetViewCropResponsive] : []),
      ].join(' ')}
    >
      <div
        className={[
          styles.streetViewContainer,
          ...(responsiveHeight ? [styles.streetViewContainerResponsive] : []),
        ].join(' ')}
        ref={mapContainer}
        id="street-view"
      />
      {(address1 || address2) && (
        <div
          style={{
            backgroundColor: 'rgba(0,0,0,0.8)',
            borderRadius: 'var(--border-radius)  var(--border-radius) 0 0',
            color: '#e9e9e9',
            padding: '13px 12px',
            position: 'absolute',
            bottom: '0',
            width: '100%',
            height: '100px',
            zIndex: 2,
          }}
        >
          {address1}
          <br />
          <span style={{ color: '#9b9b9b' }}>{address2}</span>
        </div>
      )}
    </div>
  )
}
