import React from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'

import Header from '../Header'

import styles from './Layout.module.css'
import Projects from '../Projects'
import { mobile } from '../../styles/breakpoint'

const Layout = ({ children, location, project, ...props }) => {
  return (
    <>
      <div style={{ display: 'flex' }}>
        <Sidebar id="sidebar">
          <Projects location={location} openProject={project} />
        </Sidebar>
        {children}
      </div>
      <footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

function Sidebar({ children, ...props }) {
  return (
    <div {...props} className={styles.sidebar}>
      <Header />
      {children}
    </div>
  )
}

Layout.Sidebar = Sidebar

function Content(props) {
  const isMobile = useMediaQuery({ query: mobile })

  if (isMobile) {
    return null
  }

  return <div {...props} className={styles.content} />
}

Layout.Content = Content

export default Layout
