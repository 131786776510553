import React from 'react'
import PropTypes from 'prop-types'

import Card from '../Card'
import Image from '../Image'
import Split from '../Split'
import ResponsiveVideo from '../ResponsiveVideo'

import styles from './Project.module.css'

export default function Project({ project }) {
  return (
    <article>
      {project.content.map(item => {
        if (item.image) {
          return (
            <Image
              key={item.id}
              className={styles.image}
              image={item.image}
              url={item.image_url}
              alt=""
            />
          )
        }

        if (item.embed_code) {
          return (
            <div
              className={styles.image}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: item.embed_code }}
            />
          )
        }

        if (item.vimeo_url) {
          return (
            <ResponsiveVideo
              className={styles.image}
              key={item.id}
              vimeoId={item.vimeo_url}
              width={item.width ? item.width : undefined}
              height={item.height ? item.height : undefined}
            />
          )
        }

        if (item.left || item.left_vimeo || item.left_vertical_stack) {
          return <Split key={item.id} item={item} />
        }

        return null
      })}
      <Card>
        <div className={styles.content}>
          <div className={styles.leftColumn}>
            <h3>Project Information</h3>
            {/* eslint-disable react/no-danger */}
            <div
              dangerouslySetInnerHTML={{
                __html: project.fields.projectInformationHTML,
              }}
            />
          </div>
          <div className={styles.rightColumn}>
            <h3>Credits</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: project.fields.creditsHTML,
              }}
            />
            <h3 style={{ marginTop: '2em' }}>Fonts</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: project.fields.fontsHTML,
              }}
            />
            {/* eslint-enable */}
            <span className={styles.downloadLink}>
              {'􀈽 '}
              <a href={project.download_url}>Download Project Images</a>
            </span>
          </div>
        </div>
      </Card>
    </article>
  )
}

Project.propTypes = {
  project: PropTypes.shape({
    content: PropTypes.array,
    fields: PropTypes.shape({
      projectInformationHTML: PropTypes.string.isRequired,
      creditsHTML: PropTypes.string.isRequired,
      fontsHTML: PropTypes.string.isRequired,
    }),
    download_url: PropTypes.string,
  }).isRequired,
}
