import React from 'react'
import PropTypes from 'prop-types'

import styles from './FeedItem.module.css'

export default function Header({ type, isLink, ...props }) {
  return (
    <header {...props} className={styles.header}>
      <span>{type}</span>
      {isLink && <span className={styles.link}>Show More</span>}
    </header>
  )
}

Header.propTypes = {
  type: PropTypes.string.isRequired,
  isLink: PropTypes.bool,
}

Header.defaultProps = {
  isLink: true,
}
