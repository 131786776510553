import React from 'react'

import ResponsiveVideo from '../ResponsiveVideo'
import Image from '../Image'

import styles from './Split.module.css'

function SplitItem({ item, side, ...props }) {
  const vimeoKey = `${side}_vimeo`
  const verticalStackKey = `${side}_vertical_stack`

  if (item[vimeoKey]) {
    const { width, height, vimeo_url: vimeoUrl } = item[vimeoKey]

    const isNextToStack =
      (side === 'right' && item.left_vertical_stack) ||
      (side === 'left' && item.right_vertical_stack)

    return (
      <SplitVimeo
        vimeoUrl={vimeoUrl}
        width={width}
        height={height}
        isNextToStack={isNextToStack}
      />
    )
  }

  if (item[verticalStackKey]) {
    const {
      top_url: topUrl,
      bottom_url: bottomUrl,
      top,
      bottom,
      top_vimeo: topVimeo,
      bottom_vimeo: bottomVimeo,
    } = item[verticalStackKey]

    return (
      <div className={styles.verticalStack}>
        {topVimeo ? (
          <SplitVimeo
            vimeoUrl={topVimeo.vimeo_url}
            width={topVimeo.width}
            height={topVimeo.height}
          />
        ) : (
          <Image
            className={styles.splitImage}
            image={top}
            url={topUrl}
            alt=""
          />
        )}

        {bottomVimeo ? (
          <SplitVimeo
            vimeoUrl={bottomVimeo.vimeo_url}
            width={bottomVimeo.width}
            height={bottomVimeo.height}
          />
        ) : (
          <Image
            className={styles.splitImage}
            image={bottom}
            url={bottomUrl}
            alt=""
          />
        )}
      </div>
    )
  }

  if (item[side]) {
    return (
      <Image
        className={styles.splitImage}
        image={item[side]}
        url={item[`${side}_url`]}
        alt=""
      />
    )
  }

  return null
}

function SplitVimeo({ vimeoUrl, width, height, isNextToStack = false }) {
  const video = (
    <ResponsiveVideo
      vimeoId={vimeoUrl}
      width={width || undefined}
      height={height || undefined}
      isNextToStack={false}
    />
  )

  if (isNextToStack) {
    return (
      <div className={styles.splitImage}>
        <div className={styles.stackSpacer}>{video}</div>
      </div>
    )
  }

  return <div className={styles.splitImage}>{video}</div>
}

export default function Split({ item, ...props }) {
  return (
    <div className={styles.split} {...props}>
      <SplitItem item={item} side="left" />
      <SplitItem item={item} side="right" />
    </div>
  )
}
