import React from 'react'
import PropTypes from 'prop-types'

import styles from './FeedItem.module.css'

export default function Title({ children, ...props }) {
  return (
    <h2 className={styles.title} {...props}>
      {children}
    </h2>
  )
}

Title.propTypes = {
  children: PropTypes.node.isRequired,
}
