import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useSwipeable } from 'react-swipeable'
import { Link, navigate } from 'gatsby'
import Img from 'gatsby-image'

import { ThemeContext } from '../ThemeSwitcher'
import Header from './Header'
import Title from './Title'
import Summary from './Summary'
import Card from '../Card'
import { isRelativeUrl } from '../../util'

import styles from './FeedItem.module.css'

const SWIPE_LIMIT = 80
const SWIPED_POSITION = 115
const MARGIN = 8
const BUTTON_WIDTH = SWIPED_POSITION - MARGIN
const OPEN_LIMIT = 200

export default function FeedItem({
  isLink,
  category,
  url,
  name,
  description,
  thumbnail,
}) {
  const [offset, setOffset] = useState(0)
  const [isSwiping, setIsSwiping] = useState(false)
  const [isShowingOpen, setisShowingOpen] = useState(false)
  const [willOpen, setWillOpen] = useState(false)
  const [isScrolling, setIsScrolling] = useState(false)
  const [isShaking, setIsShaking] = useState(false)

  const { theme } = useContext(ThemeContext)

  const cardBGColor = theme === 'light' ? 233 : 34
  const cardBGColorHover = theme === 'light' ? 221 : 46

  const handlers = useSwipeable({
    onSwiping: event => {
      if (event.first && (event.dir === 'Up' || event.dir === 'Down')) {
        setIsScrolling(true)
        setIsSwiping(false)
        return
      }

      if (!isScrolling) {
        if (event.event.cancelable) event.event.preventDefault()

        let delta

        if (isShowingOpen === 'left') {
          delta = event.deltaX - SWIPED_POSITION
        } else if (isShowingOpen === 'right') {
          delta = event.deltaX + SWIPED_POSITION
        } else {
          delta = event.deltaX
        }

        setOffset(delta)
        setIsSwiping(true)
      }
    },
    onSwiped: () => {
      if (Math.abs(offset) > OPEN_LIMIT) {
        if (offset < 0) {
          setWillOpen('left')
        } else {
          setWillOpen('right')
        }
        setTimeout(() => {
          if (isRelativeUrl(url)) {
            navigate(url)
          } else {
            window.location = url
          }
        }, 300)
      } else if (!isScrolling) {
        if (Math.abs(offset) > SWIPE_LIMIT) {
          setisShowingOpen(offset > 0 ? 'right' : 'left')
        } else {
          setisShowingOpen(false)
        }
      }

      setOffset(0)
      setIsScrolling(false)
      setIsSwiping(false)
    },
  })

  const calculateOpenButtonStyles = (isRight = false) => {
    const buttonStyles = {
      transitionProperty: 'width background-color',
      transitionDuration: isSwiping ? '0s' : '0.3s',
    }

    if (
      (isRight && offset < 0) ||
      (!isRight && offset > 0) ||
      (willOpen === 'left' && isRight) ||
      (willOpen === 'right' && !isRight)
    ) {
      buttonStyles.opacity = 0
      return buttonStyles
    }

    const color =
      Math.abs(offset) > OPEN_LIMIT || willOpen ? cardBGColorHover : cardBGColor

    // bgColor
    let opacity = 1
    if ((!isShowingOpen || isSwiping) && !willOpen) {
      opacity = Math.abs(Math.min(1, offset / SWIPED_POSITION))
    }

    buttonStyles.backgroundColor = `rgba(${color},${color},${color},${opacity})`

    // transform
    buttonStyles.width = willOpen
      ? '100%'
      : `${Math.max(
          BUTTON_WIDTH,
          BUTTON_WIDTH + (Math.abs(offset) - SWIPED_POSITION)
        )}px`

    return buttonStyles
  }

  const calculateCardStyles = () => {
    const cardStyles = {
      transitionProperty: 'transform',
      transitionDuration: isSwiping ? '0s' : '0.3s',
      position: 'relative',
      zIndex: 1,
    }

    if (isSwiping) {
      cardStyles.backgroundColor = 'var(--module-bg-color-hover)'
    }

    if (willOpen === 'left') {
      cardStyles.transform = `translateX(calc(100% + 8px))`
      return cardStyles
    }

    if (willOpen === 'right') {
      cardStyles.transform = `translateX(calc(-100% - 8px))`
      return cardStyles
    }

    if (isShowingOpen === 'left' && !isSwiping) {
      cardStyles.transform = `translateX(${SWIPED_POSITION}px)`
    } else if (isShowingOpen === 'right' && !isSwiping) {
      cardStyles.transform = `translateX(-${SWIPED_POSITION}px)`
    } else {
      cardStyles.transform = `translateX(${-1 * offset}px)`
    }

    return cardStyles
  }

  const cardProps = isLink ? handlers : {}

  const card = (
    <Card {...cardProps} style={calculateCardStyles()} as="article">
      <Header type={category} isLink={isLink} />
      <div className="flex">
        {thumbnail && (
          <Img
            className={styles.thumbnail}
            fadeIn={false}
            fluid={thumbnail.childImageSharp.fluid}
          />
        )}
        <div>
          <Title>{name}</Title>
          <Summary dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </div>
    </Card>
  )

  if (isLink && !url) {
    return (
      <button
        type="button"
        onClick={() => setIsShaking(true)}
        className={
          isShaking
            ? [styles.confidential, styles.shake].join(' ')
            : styles.confidential
        }
        onAnimationEnd={() => setIsShaking(false)}
      >
        {card}
      </button>
    )
  }

  if (isLink && isRelativeUrl(url)) {
    return (
      <div style={{ position: 'relative' }}>
        <Link
          style={calculateOpenButtonStyles()}
          className={styles.open}
          to={`/${url}`}
        >
          Open
        </Link>
        <Link to={`/${url}`}>{card}</Link>
        <Link
          style={calculateOpenButtonStyles(true)}
          className={[styles.open, styles.after].join(' ')}
          to={`/${url}`}
        >
          Open
        </Link>
      </div>
    )
  }

  if (isLink && !isRelativeUrl(url)) {
    return (
      <div style={{ position: 'relative' }}>
        <a
          style={calculateOpenButtonStyles()}
          className={styles.open}
          href={`${url}`}
        >
          Open
        </a>
        <a target="_blank" rel="noopener noreferrer" href={url}>
          {card}
        </a>
        <a
          style={calculateOpenButtonStyles(true)}
          className={[styles.open, styles.after].join(' ')}
          href={`${url}`}
        >
          Open
        </a>
      </div>
    )
  }

  return card
}

FeedItem.propTypes = {
  isLink: PropTypes.bool,
  category: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  thumbnail: PropTypes.any,
}

FeedItem.defaultProps = {
  isLink: true,
  thumbnail: null,
}
