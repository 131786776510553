import React, { useContext } from 'react'

import { ThemeContext } from '../ThemeSwitcher'
import styles from './SimpleHeader.module.css'

import Button from '../Button'
import Switch from '../Switch'

export default function Header(props) {
  const { theme, setTheme } = useContext(ThemeContext)

  return (
    <header className={styles.simpleHeader} {...props}>
      <Button as="a" to="/" className={styles.back}>
        Back
      </Button>

      <Switch
        onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
        isOn={theme === 'dark'}
        className={styles.switch}
      />
    </header>
  )
}
