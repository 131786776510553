import React, { useRef, useState } from 'react'
import ReactSwipe from 'react-swipe'
import PropTypes from 'prop-types'

import ResponsiveVideo from '../ResponsiveVideo'
import Image from '../Image'
import styles from './Carousel.module.css'

export default function Carousel({
  slides,
  children,
  buttonHeightOffset,
  slideIndicatorsStyle,
}) {
  const actualSlides = slides || children

  const swipeRef = useRef()
  const [currentSlide, setCurrentSlide] = useState(0)

  return (
    <div className={styles.swipeContainer}>
      <ReactSwipe
        ref={swipeRef}
        childCount={actualSlides.length}
        swipeOptions={{
          startSlide: currentSlide,
          transitionEnd: index => setCurrentSlide(index),
        }}
        style={{
          container: {
            overflow: 'hidden',
            visibility: 'hidden',
            position: 'relative',
            borderRadius: 'var(--border-radius)',
          },
          wrapper: {
            overflow: 'hidden',
            position: 'relative',
          },
          child: {
            float: 'left',
            width: '100%',
            position: 'relative',
            transitionProperty: 'transform',
          },
        }}
      >
        {actualSlides.map(slide => {
          if (slide.vimeo_url) {
            return (
              <div>
                <ResponsiveVideo
                  key={slide.id}
                  style={{
                    pointerEvents: 'none',
                  }}
                  vimeoId={slide.vimeo_url}
                  width={slide.vimeo_width ? slide.vimeo_width : undefined}
                  height={slide.vimeo_height ? slide.vimeo_height : undefined}
                  quality="1080p"
                />
              </div>
            )
          }

          if (slide.image) {
            return (
              <div>
                {slide.caption && (
                  <p className={styles.imageCaption}>{slide.caption}</p>
                )}
                <Image
                  className={styles.image}
                  key={slide.id}
                  image={slide.image}
                  url={slide.image_url}
                  loading="eager"
                  alt=""
                />
              </div>
            )
          }

          return slide
        })}
      </ReactSwipe>

      {actualSlides.length > 1 && (
        <>
          <button
            aria-label="Previous photo"
            className={styles.previous}
            onClick={() => swipeRef.current.prev()}
            type="button"
            style={
              buttonHeightOffset > 0
                ? { height: `calc(100% - ${buttonHeightOffset}px)` }
                : {}
            }
          />
          <button
            aria-label="Next photo"
            className={styles.next}
            onClick={() => swipeRef.current.next()}
            type="button"
            style={
              buttonHeightOffset > 0
                ? { height: `calc(100% - ${buttonHeightOffset}px)` }
                : {}
            }
          />
        </>
      )}

      {actualSlides.length > 1 && (
        <div className={styles.slideIndicators} style={slideIndicatorsStyle}>
          {actualSlides.map((_, i) => (
            <button
              aria-label={`Go to slide ${i}`}
              type="button"
              onClick={() => swipeRef.current.slide(i)}
              className={[
                styles.slideIndicator,
                ...(i === currentSlide ? [styles.active] : []),
              ].join(' ')}
            />
          ))}
        </div>
      )}
    </div>
  )
}

Carousel.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      vimeo_height: PropTypes.number,
      vimeo_width: PropTypes.number,
      vimeoId: PropTypes.string,
      image: PropTypes.object,
      image_url: PropTypes.string,
    })
  ),
  children: PropTypes.arrayOf(PropTypes.node),
  buttonHeightOffset: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  slideIndicatorsStyle: PropTypes.object,
}

Carousel.defaultProps = {
  slides: null,
  children: null,
  buttonHeightOffset: 0,
  slideIndicatorsStyle: {},
}
