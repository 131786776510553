import React from 'react'
// import { useMediaQuery } from 'react-responsive'
import { graphql, useStaticQuery } from 'gatsby'

import FeedItem from '../FeedItem/FeedItem'
import Project from '../Project'
import About from '../About'

import { ShowLess } from '../Button'

export default function Projects({ openProject }) {
  const data = useStaticQuery(graphql`
    {
      allStrapiFeed {
        nodes {
          feed_items {
            id
            category
            content
            title
            url
            description

            thumbnail {
              childImageSharp {
                fluid(maxWidth: 110) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }

            about_page {
              id
              about
              slug
            }

            project {
              id
              description
              slug
              name
              thumbnail {
                childImageSharp {
                  fluid(maxWidth: 110) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
          children {
            ... on StrapiAboutPage {
              ...About
            }
          }
        }
      }
    }
  `)

  const {
    allStrapiFeed: { nodes },
  } = data

  const [feed] = nodes
  const { feed_items: feedItems } = feed

  return (
    <nav>
      {openProject && !openProject.isAbout && (
        <div className="mobile-only">
          <ShowLess as="a" to="/" />
          <FeedItem
            category="Projects"
            url={openProject.slug}
            name={openProject.name}
            description={openProject.description}
            thumbnail={openProject.thumbnail}
            isLink={false}
          />
          <Project project={openProject} />
        </div>
      )}
      {openProject &&
        openProject.isAbout &&
        (() => {
          const aboutItem = feedItems.find(item => item.about_page)

          return (
            <div className="mobile-only">
              <ShowLess as="a" to="/" />
              <FeedItem
                category="Info"
                url={openProject.slug}
                name="About us"
                description={aboutItem.description}
                thumbnail={openProject.thumbnail}
                isLink={false}
              />
              <About data={openProject} />
            </div>
          )
        })()}

      {feedItems.map(item => {
        if (
          (item.project && item.project.slug === null) ||
          (item.about_page && item.about_page.slug === null)
        ) {
          return null
        }

        let cardData
        if (item.about_page) {
          cardData = {
            key: item.about_page.slug,
            category: 'Info',
            url: item.about_page.slug,
            name: 'About us',
            description: item.description,
          }
        } else if (item.project) {
          cardData = {
            key: item.project.slug,
            category: 'Projects',
            url: item.project.slug,
            name: item.project.name,
            description: item.project.description,
            thumbnail: item.project.thumbnail,
          }
        } else {
          cardData = {
            key: `${item.url}${item.title}`,
            category: item.category,
            url: item.url,
            name: item.title,
            description: item.content,
            thumbnail: item.thumbnail,
          }
        }

        return (
          <div
            className={
              openProject &&
              ((item.about_page && openProject.isAbout) ||
                (item.project && openProject.slug === item.project.slug))
                ? 'desktop-only'
                : ''
            }
            key={cardData.key}
          >
            <FeedItem {...cardData} />
          </div>
        )
      })}
      <div className="mobile-only">
        <FeedItem
          category="News"
          url="news"
          name="Studio Updates"
          description="Check out the latest news, features and events from the studio<br/><br/>"
        />
      </div>
    </nav>
  )
}
