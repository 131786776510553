import React from 'react'
import PropTypes from 'prop-types'

import styles from './FeedItem.module.css'

export default function Summary({ children, ...props }) {
  return (
    <p className={styles.summary} {...props}>
      {children}
    </p>
  )
}

Summary.propTypes = {
  children: PropTypes.node.isRequired,
}
