/* eslint-disable react/button-has-type */
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import Card from '../Card'
import styles from './Button.module.css'
import cardStyles from '../Card/Card.module.css'

import Caret from './img/caret.inline.svg'

export default function Button({ className, as, ...props }) {
  if (as === 'a') {
    return (
      <Link
        className={[cardStyles.card, styles.button, className].join(' ')}
        {...props}
      />
    )
  }

  return <Card className={[className, styles.button].join(' ')} {...props} />
}

Button.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['button', 'submit']),
}

Button.defaultProps = {
  as: 'button',
  type: 'button',
}

export function ShowLess({ as, ...props }) {
  if (as === 'a') {
    return (
      <Link
        className={[cardStyles.card, styles.button].join(' ')}
        style={{ width: 93, float: 'right', borderRadius: 20 }}
        {...props}
      >
        <Caret />
        Show&nbsp;Less
      </Link>
    )
  }

  return (
    <Button as={as} {...props}>
      <Caret />
      Show&nbsp;Less
    </Button>
  )
}

export function ShowAll({ className, ...props }) {
  return (
    <Link
      {...props}
      className={[
        cardStyles.card,
        styles.button,
        styles.showAll,
        className,
      ].join(' ')}
      to="/all"
    >
      Show All Projects
    </Link>
  )
}
